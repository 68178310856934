import React, { useState, useEffect } from "react"

const marketoScriptId = "mktoForms"

export default function MarketoForm({
  formId,
  handleClose,
  isSubscription,
  formType,
}) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isRefreshed, setRefresh] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)
  const [timer, setTimer] = useState(8)

  // const handlePopupClose = () => {
  //   sessionStorage.removeItem("isSubmitted")
  // }

  useEffect(() => {
    if (!document.getElementById(marketoScriptId)) {
      loadScript()
    } else {
      setIsLoaded(true)
    }
  }, [])

  useEffect(() => {
    let timeInterval
    if (isSubmitted) {
      if (typeof window !== "undefined") {
        setTimeout(() => {
          sessionStorage.removeItem("isSubmitted")
          setRefresh(true)
          if (handleClose) handleClose()
          window.location.reload()
        }, 8000)
      }
      timeInterval = setInterval(() => {
        setTimer(prev => prev - 1)
      }, 1000)
    }
    return () => {
      if (timeInterval) clearInterval(timeInterval)
    }
  }, [isSubmitted])

  useEffect(() => {
    if (isLoaded) {
      window &&
        window.MktoForms2 &&
        window.MktoForms2.loadForm(
          "//go.confluent.io",
          "582-QHX-262",
          formId,
          function (form) {
            form.onSuccess(function (values, followUpUrl) {
              sessionStorage.setItem("isSubmitted", "true")
              setSubmitted(true)

              // Return false to prevent the submission handler from taking the lead to the follow up url
              return false
              // Get the form's jQuery element and hide it
              // form.getFormElem().hide()
            })
            return true
          }
        )
    }
  }, [isLoaded, formId])

  // useEffect(() => {
  //   isLoaded &&
  //     window.MktoForms2.loadForm("//go.confluent.io", "582-QHX-262", formId)
  // }, [isLoaded, formId])

  const loadScript = () => {
    var s = document.createElement("script")
    s.id = marketoScriptId
    s.type = "text/javascript"
    s.async = true
    s.src = "//go.confluent.io/js/forms2/js/forms2.min.js"
    s.onreadystatechange = function () {
      if (this.readyState === "complete" || this.readyState === "loaded") {
        setIsLoaded(true)
      }
    }
    s.onload = () => setIsLoaded(true)
    document.getElementsByTagName("head")[0].appendChild(s)
  }

  return (
    <>
      <form id={`mktoForm_${formId}`}></form>

      {typeof window !== "undefined" &&
        sessionStorage.getItem("isSubmitted") &&
        !isRefreshed &&
        isSubmitted &&
        timer && (
          <div
            className="event_popup"
            id="thankyou"
            style={{ display: "flex" }}
          >
            <div className="event_popup_body">
              <div className="event_popup_close">
                <span>Closing in {timer} seconds</span>
              </div>
              {isSubscription === undefined &&
                formType === "speaker-agreement" && (
                  <div className="event_popup_thankmsg">
                    <h2>Thank you for completing the agreement.</h2>
                    <p>You will receive an email confirmation.</p>
                  </div>
                )}
              {isSubscription === undefined && formType === "become-a-sponsor" && (
                <div className="event_popup_thankmsg">
                  <h2>Thank you for completing the sponsorship form.</h2>
                  <p>We will reach out soon.</p>
                </div>
              )}
              {isSubscription === undefined && formType === "sign-up" && (
                <div className="event_popup_thankmsg">
                  <h2>Thank you for signing up for updates.</h2>
                  <p>We will reach out soon.</p>
                </div>
              )}
              {isSubscription && (
                <div className="event_popup_thankmsg">
                  <h2>Thank you for subscribing.</h2>
                  <p>You will receive an email confirmation.</p>
                </div>
              )}
            </div>
          </div>
        )}
    </>
  )
}
