import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
const BannerHome = props => {
  const data = useStaticQuery(graphql`
    query {
      contentfulHomePage {
        title
        kafkaSummitImage {
          file {
            url
          }
        }
        bannerImageLeft {
          file {
            url
          }
        }
        bannerImageRight {
          file {
            url
          }
        }
        bannerImageLeftMobile {
          file {
            url
          }
        }
        bannerImageRightMobile {
          file {
            url
          }
        }
        confluentLogo {
          file {
            url
          }
        }
      }
    }
  `)
  const [windowWidth, setWindowWidth] = useState(() => {
    if (typeof window !== "undefined") return window.innerWidth
  })
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth)
    })
    return () => {
      window.removeEventListener("resize", () => {})
    }
  }, [])

  return (
    <>
      <div className="banner_main">
        <div className="banner_ct_data">
          <h1 className="banner_title">
            <img src={data?.contentfulHomePage?.kafkaSummitImage?.file?.url} />
          </h1>
          <div className="banner_logo">
            <img
              src={data?.contentfulHomePage?.confluentLogo?.file?.url}
              alt="confluent-logo"
            ></img>
          </div>
        </div>
      </div>
    </>
  )
}
export default BannerHome
