import React, { useEffect } from "react"
import "../styles/index.scss"
import HomePageHeader from "./homepageheader"

import Footer from "./footer"
const HomePageLayout = props => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = window.location.search
      if (!params) {
        const storedParams = sessionStorage.getItem("params")
        if (storedParams) {
          window.history.replaceState(
            {},
            "",
            window.location.href + storedParams
          )
        }
        return
      }
      sessionStorage.setItem("params", params)
    }
  }, [])
  return (
    <>
      <div className="main_wrap">
        <div className="container">
          {/* <HomePageHeader /> */}
          {props.children}
          <Footer />
        </div>
      </div>
    </>
  )
}

export default HomePageLayout
