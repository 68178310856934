import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

const EventList = props => {
  const [search, setSearch] = useState(() => {
    if (typeof window !== "undefined") return window.location.search
    return ""
  })
  const [url, setPageUrl] = useState(() => {
    if (typeof window !== "undefined") return window.location.href
    return ""
  })

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      setSearch(window.location.search)
      setPageUrl(window.location.href)
      setLoaded(true)
    }
  })

  return (
    <div className="event_list_main">
      {props &&
        props.events &&
        props.events.map((item, index) => {
          return (
            <Link
              to={`${item.learnMoreUrl}${search}`}
              className="event_thumb"
              key={index}
              target={item?.learnMoreExternal && "_blank"}
            >
              <h2 className="event_thumb_title">
                {item?.eventImage?.file?.url ? (
                  <img src={item?.eventImage?.file?.url} />
                ) : (
                  item?.eventName
                )}
              </h2>
              <div className="event_thumb_date">
                {item?.startDate === null
                  ? item?.endDate
                  : item?.eventStartMonth === item?.eventEndMonth
                  ? `${item?.startDate} - ${item?.eventEndDate}`
                  : `${item?.startDate} - ${item?.endDate}`}{" "}
              </div>
              {/* {loaded && (
                <>
                  <div className="event_thumb_btns">
                    {item?.status === "Upcoming" && (
                      <>
                        {!item?.learnMoreExternal && item?.learnMoreUrl && (
                          <Link
                            to={`${item.learnMoreUrl}${search}`}
                            className="event_thumb_btn"
                          >
                            LEARN MORE
                          </Link>
                        )}
                         {item?.learnMoreExternal && item?.learnMoreUrl && (
                          <a
                            href={`${item.learnMoreUrl}${search}`}
                            className="event_thumb_btn"
                            target="_blank"
                            rel="noreferrer"
                          >
                            LEARN MORE
                          </a>
                        )} 
                        {item?.registrationUrl &&
                          item?.registrationUrl?.includes("http") && (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`${item?.registrationUrl}${search}`}
                              className="event_thumb_register"
                            >
                              {item?.registrationCtaText}
                            </a>
                          )}

                        {item?.registrationUrl &&
                          !item?.registrationUrl?.includes("http") && (
                            <Link
                              to={`${item?.registrationUrl}${search}`}
                              className="event_thumb_register"
                            >
                              {item?.registrationCtaText}
                            </Link>
                          )}
                      </>
                    )}
                     {item?.status === "Coming Soon" && (
                      <h4 className="event_coming_soon">COMING SOON</h4>
                    )}
                    {item?.hasGetStarted && (
                      <button
                        className="event_thumb_getstarted"
                        onClick={props.onPopupOpen}
                      >
                        GET UPDATES
                      </button>
                    )} 
                  </div>
                </>
              )} */}
            </Link>
          )
        })}
    </div>
  )
}
export default EventList
