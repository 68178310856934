import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import HomePageLayout from "../components/homepagelayout"
import BannerHome from "../components/banner-home"
import EventList from "../components/eventlist"
import Head from "../components/head"
import MarketoForm from "../components/marketoform"
import HomePageHeader from "../components/homepageheader"
const HomePage = props => {
  useEffect(() => {
    sessionStorage.removeItem("activeDate")
    sessionStorage.removeItem("scrollPosition")
    sessionStorage.removeItem("activeGreen")
    sessionStorage.removeItem("activeOrange")
    sessionStorage.removeItem("activePink")
    sessionStorage.removeItem("activePurple")
    sessionStorage.removeItem("activeBlue")
    sessionStorage.removeItem("activeLavender")
    sessionStorage.removeItem("activeRed")
    sessionStorage.removeItem("activeViolet")
    sessionStorage.removeItem("activeLima")
  })
  const data = useStaticQuery(graphql`
    query {
      contentfulHomePage {
        title
        backgroundImage {
          file {
            url
          }
        }
        events {
          ... on ContentfulEvents {
            title
            slug
            eventName
            eventImage {
              file {
                url
              }
            }
            status
            startDate(formatString: "MMMM D")
            eventEndDate: endDate(formatString: "D, YYYY")
            endDate(formatString: "MMMM D, YYYY")
            eventStartMonth: startDate(formatString: "MMMM")
            eventEndMonth: endDate(formatString: "MMMM")
            registrationUrl
            registrationCtaText
            hasGetStarted
            learnMoreUrl
            learnMoreExternal
          }
        }
        seoMetadata {
          title
          description {
            description
          }
        }
      }
    }
  `)

  useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) {
        document.getElementById("event_popup").style.display = "none"
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.target.id === "event_popup") {
        document.getElementById("event_popup").style.display = "none"
      }
    }
    window.addEventListener("mousedown", close)
    return () => window.removeEventListener("mousedown", close)
  }, [])

  const getStartedPopupClose = () => {
    document.getElementById("event_popup").style.display = "none"
  }

  const getStartedPopupOpen = () => {
    document.getElementById("event_popup").style.display = "flex"
  }

  return (
    <HomePageLayout>
      <Head
        title={data?.contentfulHomePage?.seoMetadata?.title}
        description={
          data?.contentfulHomePage?.seoMetadata?.description?.description
        }
      />
      <div className="homepage_header">
        <img
          src={data?.contentfulHomePage?.backgroundImage?.file?.url}
          className="banner_img"
        />
        <div className="banner_data">
          <HomePageHeader />
          <BannerHome title={data?.contentfulHomePage?.title} />
        </div>
      </div>
      <EventList
        events={data?.contentfulHomePage?.events}
        onPopupOpen={getStartedPopupOpen}
      />
      <div className="event_popup" id="event_popup">
        <div className="event_popup_body_getstarted">
          <div className="event_popup_close">
            <span onClick={getStartedPopupClose}>X</span>
          </div>
          <h4 className="event_get_started_header">Get Updates</h4>
          <MarketoForm
            formId="6838"
            handleClose={getStartedPopupClose}
            isSubscription={true}
          />
        </div>
      </div>
    </HomePageLayout>
  )
}
export default HomePage
